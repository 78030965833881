.ProjectPlanChip {
	&--type {
		max-width: 120px;
	}
	&--status {
		max-width: 120px;
	}
	&--priority {
		max-width: 120px;
	}
	&--tasks {
		max-width: 120px;
	}
	&--stage {
		max-width: 100px; // 120px still overflowed the map pin, hence 100px
	}
}
