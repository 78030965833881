@import "~StyleVariables";

.ClientCardContent__ProjectPlan {
	background-color: $white;
	height: 100%;
	margin-bottom: $space * 12;

	.Table {
		margin-top: $space * 3;
	}

	.TableColumn {
		width: 100px;
	}
}
