@import "~StyleVariables";

.ClientCardContent__Support {
	padding-bottom: 0px !important;

	table {
		table-layout: fixed;
	}

	&__header {
		padding: $space * 4;
		padding-bottom: $space * 2;
	}

	&__subject {
		width: 33%;
	}

	&__user-name {
		display: flex;
		align-items: center;
	}
}
