@import "~StyleVariables";
@import "~StyleAnimations";

.CreateAppointmentAction {
	&__date-time {
		width: 33%;
	}

	&__custom-title {
		margin-top: 4px;
	}

	$tag-list-width: 280px;

	overflow-x: visible !important;
	overflow-y: visible !important;

	&__content {
		width: 750px;
		max-height: 95vh;
		overflow-y: auto !important;
		background-color: $white; // This is so the tag list won't bleed through the modal when animating
	}

	&__tags {
		position: absolute;
		visibility: hidden;
		top: 0;
		right: 0;
		height: 100%;
		width: $tag-list-width;
		z-index: -1;

		transition: all 300ms cubic-bezier(0.8, 0, 0.8, 1);

		&--visible {
			visibility: visible;
			transform: translateX($tag-list-width);
		}

		#tag-list {
			top: 0;
			height: 100%;
			width: $tag-list-width;

			#hide-tags-btn {
				position: absolute;
				top: 5px;
				right: 10px;
			}

			> div,
			ul,
			h2,
			p {
				width: $tag-list-width !important;
			}
		}
	}

	&__custom-field-input {
		width: 100%;
	}
	.NumberInput {
		.Input {
			width: 100%;
		}
	}
}
