@import "~StyleVariables";

.NewMail {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 50px);

	&__mail-editor-wrapper {
		flex: 1;
	}

	.TextEditor {
		height: 100%;
		.cke {
			height: 100%;
		}
		.cke_inner {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		.cke_contents {
			flex-grow: 1;
		}
		.cke_bottom {
			display: none;
		}
	}
	&__footer {
		background-color: $white;
		.MailAttachment {
			display: inline-flex;
		}
	}
	&__customerInGroupStateFrame {
		.Block--margin-bottom-large {
			margin-bottom: 0;
		}
	}
}
.NewMail__mail-editor-wrapper {
	position: relative;

	& > .FileUploadButton {
		position: absolute;
		top: 4px;
		right: 0px;
		width: 120px;
	}
}
