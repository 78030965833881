.ProjectMapModal {
    #up-list-filters {
        z-index: 1; // Needed to place filters on top of close button in modal header

        .actions {
            #list-filters-wrap {
                top: 0;
            }
        }
    }

}