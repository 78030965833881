@import "~StyleVariables";

.MarketShareTable {
    width: 100%;
    background-color: white;
    border: 1px solid $grey-4;
    border-radius: $space * 2;

    .DistributionBar {
        margin-bottom: $padding;
    }

    &__header {
        background-color: $grey-1;
        padding: $padding 0;
    }

    &__row {
        background-color: $white;
        height: 68px; // To ensure the same height, regardless if we have a journey-step or not
        
        .TableColumn {
            padding: $padding;
        }
    }

    &__larger-col {
        max-width: 200px;
    }

    &__smaller-col {
        min-width: 125px;
    }
}
