@import "~StyleVariables";
.ProjectPlanStages {
	&__table-row--editing {
		background-color: $grey-1;
		height: 60px;
	}

	&__table-row {
		.ProjectPlanStages__name-column {
			width: 220px;

			.Tooltip {
				width: inherit;

				.Text {
					width: inherit;
				}
			}
		}
	}

	&__input-column {
		width: 220px;
		.Input {
			flex-grow: 1;
			flex-shrink: 1;
		}
		.Progress {
			margin-left: $space;
			align-self: center;
			height: 20px;
			width: 20px;
		}
	}

	&__color-column {
		width: 100px;
	}

	&__actions-column {
		min-width: 110px;
	}

	.color-input {
		border-radius: $border-radius * 2;
		border: 1px solid $grey-8 !important;
		text-transform: uppercase;
		width: 72px;
		height: 24px;
		text-align: center;
	}
}
