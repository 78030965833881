@import "~StyleVariables";

$width: 1000px;
$header-height: 50px;

.EditPhonecall {
	&--hasGroupBonanza {
		width: 1100px;
	}

	width: $width;
	height: 100vh;
	display: flex;
	flex-flow: column;
	align-content: flex-start;

	.EditPhonecallHeader {
		flex: 0 0 50px;
	}

	&__content {
		overflow: hidden;
		flex: auto;
	}

	@media (max-width: $width) {
		& {
			width: 750px;
		}
	}

	&__client-contact-info {
		z-index: 999;
	}

	&__outcome-history-row {
		height: 100%;
		> .Column {
			height: 100%;
			overflow: hidden;
		}
	}

	&__client-contact-col {
		display: flex;

		& > .Block {
			flex: 1;
		}
	}

	&__integrations-title {
		height: 47px;
		position: relative;
		display: flex;
		align-items: center;
		background-color: $grey-4;
		padding-left: 15px;
	}

	.ui-element-widget {
		padding: 15px 15px;
	}

	&__right {
		height: 100%;
		display: flex;
		flex-flow: column;
		align-content: flex-start;
	}
	&__right-top,
	&__right-bottom {
		height: 50%;
	}
	&__right-top {
		display: flex;
		flex-direction: column;
		height: 100%;
		z-index: 2;
		overflow-y: auto;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
		padding: 16px 16px;

		.EditPhonecallNotes {
			margin-top: 10px;
			position: unset;
			min-height: 250px;
		}
	}
	&__right-bottom {
		overflow-y: auto;
	}
	&__unsetCard {
		position: unset;
	}
}
