@import "~StyleVariables";

.AdminProjectPlanType {
	&__merge-content {
		.Select {
			min-width: 240px;
			max-width: 240px;
		}

		.Row {
			column-gap: $space * 2;
			margin-top: $space * 4;
		}

		.AdminProjectPlanType__merge-name-input {
			width: 480px + 2 * $space;
		}
	}

	&__table-row {
		.AdminProjectPlanType__name-column {
			width: 300px;

			.Tooltip {
				width: inherit;

				.Text {
					width: inherit;
				}
			}
		}
	}
}
