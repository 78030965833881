@import "~StyleVariables";

.ProjectBoardPreview {
	&__board-card {
		width: 100%;

		.CardHeader__title {
			font-size: 16px;
		}

		.CardHeader__subtitle {
			font-size: 12px;
		}
	}

	&__board-preview {
		border: 1px solid $grey-6;
		border-radius: $border-radius;
		overflow: auto;
	}

	&__preview-stage {
		position: relative;
		min-width: 120px;
		z-index: 0;
		height: 200px;
		width: 160px;
		border: 1px solid $grey-6;
		border-radius: $border-radius;
	}

	&__preview-stage:before {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 75%;
		content: "";
		background-color: $grey-2;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}
