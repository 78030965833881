@import "~StyleVariables";

.TemplateProductLists {
	&__table-wrapper {
		border: $border;
		border-radius: $border-radius;
		overflow: hidden;
	}

	&__productList-wrapper {
		border: $border;
		border-radius: $border-radius;
		padding: $space * 3 $space * 4;
	}

	&__clickable:hover {
		cursor: pointer;
	}

	&__product-column {
		width: 300px;
	}

	&__product-select {
		width: 100%;
	}

	&__quantity-column {
		width: 100px;
	}

	.TableColumn {
		padding-top: $space * 2;
		padding-bottom: $space * 2;
	}

	.Icon:hover {
		cursor: pointer;
	}

	&__productList-input {
		width: 50%;
	}

	&__edit-productList-input {
		flex: 1;
	}

	.Progress {
		top: 0px;
	}

	&__icon-button {
		.Icon {
			margin: 0;
		}
	}
}
