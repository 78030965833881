.QuickCreateMenu .QuickCreateMenu__item--inactive {
	font-style: italic;
	background-color: $grey-1;
	cursor: not-allowed;

	a,
	a:hover {
		pointer-events: none;
		color: $grey-8;
	}
}

#account-card {
	$responsive-width: 1450px;
	$address-break: 1395px;
	@include card(97px);
	position: relative;

	.up-paginator-wrap {
		margin-top: 10px;

		.up-paginator {
			padding-left: 0px;
		}
	}

	&:not(.has-timeline) {
		background-color: $grey-2;
	}

	.SignalsToggle {
		box-sizing: border-box;
		height: 50px;
		border-bottom: 4px solid transparent;
		&:hover {
			border-bottom: 4px solid $grey-6;
		}
	}

	.NotesFilterButtonContainer {
		display: inline;
	}

	.card-header {
		height: 58px !important;
		#nav {
			.responsive {
				visibility: hidden;
			}

			&.visible {
				.responsive {
					visibility: visible;
				}
			}
		}

		.right {
			.SignalsToggle {
				#info-box {
					right: 8px;
				}
			}
		}

		.QuickCreateMenu {
			margin-right: 8px;
		}

		#add-button,
		#similarCompanies-account-button {
			margin-top: 8px;

			b {
				margin-right: 5px;
			}
		}

		#similarCompanies-account-button {
			margin-right: 10px;
		}
	}

	#top-section {
		.ui-element-card-account-card-top {
			#ui-elements-wrapper {
				display: flex;
				padding: 0;

				> div {
					padding: 12px 16px;
					flex: 1;

					&:not(:last-child) {
						border-right: 2px solid $grey-2;
					}
				}
			}

			iframe {
				height: 100%;
			}

			.ui-element-widget {
				background-color: $white;
				border-bottom: 0;
			}
		}
	}

	#card-sidebar {
		padding-top: 0px;

		.title {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
			top: 5px;
			margin-bottom: 10px;

			h3 {
				padding-top: 20px;
			}
		}

		.sidebar-top--isSubaccount:not(.sidebar-top--inactive) {
			background-color: $super-light-blue;

			.sidebar-section.sidebar-notes-section .notes-empty:hover {
				background: #{darken($super-light-blue, 5%)};
			}
		}

		.sidebar-top--inactive {
			background-color: $grey-1;

			h2 {
				font-style: italic;
				color: $grey-10;
				padding-top: 15px;
			}
		}

		.InactiveLabel {
			position: absolute;
			top: -10px;
			right: -10px;
		}

		.sidebar-section {
			$size: 40px;
			$widget-width: 270px;
			margin-top: 15px;
			margin: 0px -15px;
			padding: 10px 15px;

			&.sidebar-address-section {
				min-height: 50px;
				cursor: pointer;
				transition: ease all 200ms;
				margin: 0px -15px;
				padding: 10px 15px;

				&:hover {
					background-color: $grey-1;
				}

				.visit-adress-icon {
					position: absolute;
					right: 10px;
					top: 10px;

					&.has-address {
						top: 20px;
						right: 20px;
					}
				}
			}

			&.sidebar-notes-section {
				.notes-empty {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					color: $grey-8;
					transition: all ease-out 200ms;
					padding-left: 0;

					font-style: italic;
					border-radius: $border-radius;

					i {
						width: 20px;
					}

					&:hover {
						i {
							color: $grey-10;
						}
						color: $grey-10;
						background: $grey-2;
						padding: 8px;
					}
				}
			}

			&.sidebar-section-group-selector {
				z-index: 1002;
			}

			.contact-info {
				.icon {
					color: $blue;
				}
			}

			&.no-bottom-line {
				border-bottom: 0;
				padding-bottom: 0;
			}

			.sub-header {
				color: $grey-10;
				text-transform: uppercase;
			}

			&#account-sidebar-contacts {
				margin-left: -15px;
				margin-right: -15px;
				padding: 15px;
				background-color: $grey-1;
				border-top: 1px solid $grey-4;
				margin-top: -26px;
			}

			&.top-line {
				padding-top: 15px;
				border-top: 1px solid $grey-4;
				margin-bottom: 0;
			}

			.account-manager-wrapper {
				margin: 0;
				margin-top: 5px;

				// .hide-input {
				//  display:none;
				// }

				.select2-search-choice {
					color: $white;
					background-color: $blue !important;
				}

				.select2-choices {
					border: 0;
				}

				.account-manager-list {
					.account-manager {
						height: 25px;

						img {
							height: inherit;
							width: inherit;
							margin-top: 0;
							margin-left: 0;
							margin-right: 0;
							background-color: transparent;
						}
					}
				}

				.single-manager {
					margin-left: 15px;
					margin-bottom: 0;

					.generic-avatar {
						position: absolute;
						z-index: 1;
						top: 43px;
						left: 15px;

						img {
							height: inherit;
							width: inherit;
							margin-top: 0;
							margin-left: 0;
							margin-right: 0;
							background-color: transparent;
						}
					}

					.disabled {
						.generic-avatar {
							top: 35px;
						}
					}

					.select2-choice {
						border: none;
						.select2-search-choice-close {
							display: none;
						}
					}
					.select2-arrow {
						border: none;
					}
				}

				// .icon {
				//  display:none;
				// }

				.no-users {
					margin-bottom: 0px;
					.icon {
						position: absolute;
						right: 0;
						color: $grey-10;
						display: block;
						z-index: 2;
						top: 48px;
					}
				}
			}

			//  &#sidebar-address-section {
			//      min-height: 50px;
			//      transition: ease all 200ms;
			//         margin: 0px -15px;
			//         padding: 10px 15px;

			//      .visit-adress-icon {
			//          position: absolute;
			//          right: 10px;
			//          top:0px;
			//          &.has-address {
			//              top: 20px;
			//              right: 20px;
			//          }
			//      }
			//  }

			//  &#sidebar-address-section:hover {
			//      background-color: $grey-1 ;
			//  }
		}
	}

	#card-main-view {
		margin-top: 49px !important;
		padding-bottom: 300px;
		overflow-x: hidden;
		background-color: $grey-2;

		@media (max-width: 1555px) {
			.timeline-cat-divider .timeline-filter-group .btn-group button {
				span:not(.no-hide) {
					display: none;
				}
				b {
					display: inline-block;
				}
			}
		}

		.sub-header {
			h4 {
				display: inline-block;
				vertical-align: middle;
			}
		}

		#sales-tables {
			margin-top: -5px;
			background-color: $grey-1;

			.sub-header {
				.sub-header-first {
					display: inline-block;
				}

				.sub-header-last {
					cursor: pointer;
					display: inline-block;
					float: right;
					height: 40px;
					padding-top: 10px;
				}
			}

			.agreement-toggle {
				margin-right: 10px;

				.toggle-label {
					float: right;
					padding-top: 2px;
					margin-left: 5px;
					color: $bright-blue;
				}
			}

			.main-table {
				th {
					background-color: $grey-1;
					border-top: 0;
					border-left: 0;
				}

				.th-style {
					td {
						background-color: $grey-1;
						border-top: 0;
						border-left: 0;
					}
				}

				td {
					height: 40px;
				}

				tr {
					h4 {
						display: inline-block;
						vertical-align: middle;
					}

					.grey {
						color: $grey-10;
						vertical-align: middle;
						font-size: 10pt;
					}

					.table-section {
						background-color: $grey-1;
					}
					.select2-arrow {
						border: none;
					}
				}
			}

			.account-manager-wrapper:focus {
				.hide-input {
					display: block;
				}
			}

			// .visit-adress-icon {
			//  position: absolute;
			//     right: 10px;
			//     top:0px;
			//     &.has-address {
			//      top: 20px;
			//     }

			// }
		}

		#address-page {
			#hide-address {
				height: 35px;
				padding: 7px;
				color: $bright-blue;
			}

			&.hide-addresses {
				#address-card-wrap {
					display: none;
				}

				.big-map-canvas {
					top: 30px;
				}
			}

			.no-result {
				position: fixed;
				top: 287px;
				bottom: 15px;
				right: 15px;
				left: 365px;
				background-color: $grey-1;

				.addressText {
					position: absolute;
					top: 50%;
					height: 220px;
					margin-top: -110px;
					left: 50%;
					margin-left: -200px;
					width: 400px;
					text-align: center;
					line-height: 30px;
					color: $grey-8;

					h2 {
						color: $grey-8;
					}
				}
			}
		}

		#sales-chart,
		#market-chart {
			margin-right: 20px;
			width: 100%;
			background-color: $white;
			box-shadow: 0 2px 4px rgba($black, 0.2);

			.tool {
				position: absolute;
				right: 0;
				height: 40px;
				width: 40px;
				color: $grey-10;
			}

			.date-toggle,
			.market-toggle {
				position: absolute;
				left: 490px;
				top: 20px;

				&:hover {
					background-color: rgba($white, 0.8);
				}

				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.toggle-buttons {
				text-align: center;
				padding-top: 20px;
				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.market-toggle {
				left: 270px;
			}

			.circle {
				width: 10px;
				height: 10px;
				background: $bright-green;
				border-radius: 10px;
				float: left;
				margin-right: 5px;
				margin-top: 3px;
			}

			.title {
				text-transform: uppercase;
				color: $grey-10;
			}

			.sales-widget {
				background-color: $white;
				padding: 30px 0 0 30px;

				.content {
					margin: 0;

					.currency {
						font-size: 12px;
						margin-top: 2px;
						margin-left: 2px;
						color: $grey-10;
					}
					.value {
						font-size: 22px;
					}

					.value.green {
						color: $medium-green;
					}

					.next-date {
						font-size: 12px;
						color: $grey-10;
						margin-top: -2px;
					}
				}

				.event-sum {
					height: 17%;
					border-bottom: 1px solid $grey-4;

					.content {
						margin: 5px 0px 5px 10px;

						.title {
							font-size: 10px;
							white-space: nowrap;
							margin-left: 0px;

							&.no-icon {
								margin-left: 0px;
							}
						}

						.icon {
							float: left;
							margin: 10px;
							margin-top: 3px;
							font-size: 15px;
							color: $bright-blue;

							&.fa-globe {
								color: $blue;
							}
						}

						.value {
							float: none;
							white-space: nowrap;
							font-size: 20px;
						}

						@media (max-width: 1475px) {
							position: relative;

							.icon {
								margin-top: 2px;
								margin-left: 2px;
							}
							.value {
								position: absolute;
								top: 10px;
								font-size: 17px;
								max-width: 70px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}

				.event-sum:last-child {
					.content {
						margin-left: 10px;
					}
				}

				.up-icon-recurring-order {
					color: $bright-green;
					float: left;
					margin-right: 3px;
				}

				.year-sum {
					height: 45%;
				}
				.next-agreement {
					height: 51%;
				}

				.event-sum:last-child {
					border-bottom: 0;
				}
			}

			.ad-widget {
				position: absolute;
				right: 0;
				width: 270px;
				padding-top: 12px;
				top: 0px;
				border-left: 1px solid $grey-8;
				height: 220px;
				background-color: $white;

				@media (max-width: 1475px) {
					display: none;
				}
				.content {
					padding: 13px;
					padding-bottom: 5px;
					padding-top: 2px;

					.icon {
						margin: 15px 10px 0px 2px;
						float: left;
						font-size: 20px;
					}

					.value {
						font-size: 20px;
					}

					.fa-eye {
						color: $purple;
					}
				}
				.event-sum {
					border-bottom: 1px solid $grey-8;
				}

				.event-list {
					.content {
						font-size: 11px;

						.creative-row {
							position: relative;
							padding-left: 20px;

							.icon {
								margin: 8px;
								margin-left: 0;
								font-size: 15px;
								color: $bright-blue;
								position: absolute;
								left: 0;
							}

							.link {
								color: $bright-blue;
							}

							.subtitle {
								white-space: nowrap;
								width: 190px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}

			.chart {
				position: absolute;
				left: 250px;
				top: 70px;
				right: 0;
				height: 135px;
			}

			#green-line {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $bright-green;
				left: 0;
				top: 265px;
				z-index: 1;
			}
		}

		#sales-chart {
			display: -webkit-inline-box;
			height: 240px;
			width: 100%;

			@media (max-width: 1180px) {
				.market-toggle {
					left: 170px !important;
				}
			}

			.date-toggle {
				position: absolute;
				left: 270px;
				top: 20px;

				&:hover {
					background-color: rgba($white, 0.8);
				}

				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.circle {
				width: 10px;
				height: 10px;
				background: $bright-green;
				border-radius: 10px;
				float: left;
				margin-right: 5px;
				margin-top: 3px;
			}

			.title {
				text-transform: uppercase;
				color: $grey-10;
				font-size: 12px;
			}

			.sales-widget {
				height: 205px;
				width: 210px;
				background-color: $white;

				.content {
					.currency {
						font-size: 12px;
						margin-top: 2px;
						margin-left: 2px;
						color: $grey-10;
						display: inline-block;
					}
					.value {
						font-size: 22px;
						display: inline-block;
					}

					.next-date {
						font-size: 12px;
						color: $grey-10;
					}
				}

				.up-icon-recurring-order {
					color: $bright-green;
					float: left;
					margin-right: 3px;
				}

				.year-sum {
					height: 45%;
				}
				.next-agreement {
					height: 51%;

					&.no-re-order {
						.re-order-placeholder {
							display: block;
						}
						.re-order-info {
							display: none;
						}
					}

					.re-order-placeholder {
						display: none;
						color: $grey-6;
						margin-top: 20px;
					}
				}
			}

			.chart {
				position: absolute;
				right: 0;
				left: 250px;
				top: 70px;
				height: 135px;
			}

			#green-line {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $bright-green;
				left: 0;
				top: 205px;
				z-index: 1;
			}

			@media (max-width: 1180px) {
				& {
					.sales-widget {
						width: 145px !important;

						.title {
							width: 0px;
							height: 19px;
							overflow: hidden;
							display: block;
							white-space: nowrap;
						}

						.next-agreement {
							.re-order-placeholder {
								font-size: 12px;
							}
						}
					}
					.chart {
						left: 145px !important;
					}
				}
			}
		}

		#sales-chart-cm {
			@extend #sales-chart;
			height: 300px;

			.sales-widget {
				height: 265px;

				.content {
					margin: 0;

					.value {
						margin-left: 15px;
					}

					.rounded-square {
						width: 10px;
						height: 10px;
						border-radius: 2px;
						float: left;
						margin-right: 5px;
						margin-top: 3px;
					}

					.bright-green {
						background: $bright-green;
					}

					.medium-green {
						background: $medium-green;
					}
				}
				.year-sum {
					height: 30%;
				}
			}

			.chart {
				top: 85px;
			}

			#green-line {
				top: 265px;
			}
		}

		#market-chart {
			height: 265px;
			background-color: $white;
			box-shadow: 0 2px 4px rgba($black, 0.2);
			position: relative;

			.placeholder {
				color: $grey-8;
				text-align: center;
				margin-top: 88px;
				display: block;
			}

			.loading {
				text-align: center;
				span {
					color: $grey-8;
					margin-top: 120px;
				}
			}

			.sales-widget {
				height: 220px;
				padding-top: 5px;
				width: 220px;

				.event-sum {
					.event-text {
						padding-top: 5px;
						overflow-x: hidden;
						text-overflow: ellipsis;
						&.summed {
							margin-top: -11px;
						}
					}

					// .title {
					//  @media (max-width: 1475px) {
					//      display: none;
					//  }
					// }
				}
			}
			@media (max-width: 1475px) {
				.market-toggle {
					left: 100px;
				}

				.date-toggle {
					left: 320px;
				}

				.sales-widget {
					width: 80px;

					.event-sum {
						.title {
							display: none;
						}
					}
				}
			}

			.chart {
				top: 57px;
				right: 0;
				left: 0;

				@media (max-width: 1475px) {
					left: 0;
					right: 0;
				}

				&.no-ads {
					right: 0;
				}
			}

			#green-line {
				background-color: $blue;
				top: 220px;
			}

			.tool {
				position: absolute;
				right: 272px;
				top: 12px;

				&.no-ads {
					right: 0;
				}

				i {
					color: $grey-10;
				}

				.dropdown-menu {
					z-index: 22;

					li {
						margin: 10px 5px 11px 10px;
						padding-right: 34px;

						.content {
							display: inline-block;
							white-space: nowrap;
							width: calc(100% + 30px);
						}
					}
				}

				@media (max-width: 1475px) {
					right: 0;
				}
			}
		}

		#list-section {
			background-color: $grey-2;
			padding-top: 1px;

			#contact-list {
				h4 {
					margin-left: 5px;
				}

				padding-top: 20px;

				.item {
					height: 45px;

					.name {
						color: $bright-blue;
						display: block;
						margin-top: 5px;
						font-size: 12px;
					}

					.subtitle {
						font-size: 11px;
						text-transform: uppercase;

						&.grey {
							color: $grey-10;
						}
					}

					.value {
						&.right {
							float: right;
							padding: 10px;

							i {
								color: $grey-10;
							}
						}
					}
				}
			}
		}

		.sub-header {
			h4 {
				display: inline-block;
				vertical-align: middle;
			}

			.list-actions {
				float: right;

				#list-view-qsearch {
					$search-height: 28px;
					$animationMs: 100ms;

					background-color: $grey-4;
					border: 1px solid transparent;
					@include border-radius($border-radius);
					@include transition(all ease-in $animationMs);
					height: $search-height;
					margin-right: 10px;
					padding-left: $search-height;
					outline: none;
					color: $grey-13;

					&:focus {
						border-color: $grey-8;
					}

					& + .fa {
						position: absolute;
						top: 14px;
						right: 150px;
						line-height: $search-height;
						height: $search-height;
						width: $search-height;
						text-align: center;
						color: $grey-8;
					}
				}
			}
		}
		#contact-table {
			tr {
				&.item {
					height: 45px;
					.title {
						display: block;
						color: $bright-blue;
					}

					.subtitle {
						font-size: 11px;
					}
				}
			}
		}

		#sales-tables {
			margin-top: -5px;
			background-color: $grey-1;

			.agreement-toggle {
				//padding-top:20px;
				margin-right: 10px;

				.toggle-label {
					float: right;
					padding-top: 2px;
					margin-left: 5px;
					color: $bright-blue;
				}
			}

			.main-table {
				th {
					background-color: $grey-1;
					border-top: 0;
					border-left: 0;
				}

				.th-style {
					td {
						background-color: $grey-1;
						border-top: 0;
						border-left: 0;
					}
				}

				td {
					height: 40px;
					min-width: 35px;

					span.account-manager {
						line-height: 20px;
					}
				}

				tr {
					h4 {
						display: inline-block;
						vertical-align: middle;
					}

					.grey {
						color: $grey-10;
						vertical-align: middle;
						font-size: 10pt;
					}

					&.table-section {
						background-color: $grey-1;
					}
					.select2-arrow {
						border: none;
					}
				}
			}

			// .account-manager-wrapper:focus {
			//  .hide-input {
			//      display:block;
			//  }
			// }

			// .visit-adress-icon {
			//  position: absolute;
			//     right: 10px;
			//     top:0px;
			//     &.has-address {
			//      top: 20px;
			//     }

			// }
		}

		.list-wrap {
			#title-dashboard {
				margin-bottom: 5px;
				text-transform: uppercase;
				font-size: 12px;
				color: $grey-10;
			}

			@media (max-width: 1120px) {
				#dashboard-navbar {
					display: none;
				}
			}

			#dashboard-navbar {
				margin-top: 25px;
				margin-left: 35px;

				> .btn-group {
					border: 1px solid $grey-4;
					background-color: $white;
					.up-btn {
						border: 0;
						padding: 0;
						margin-right: 0;
						> .btn-content {
							padding-left: 20px;
							padding-right: 20px;
						}
					}
					> .dropdown {
						display: inline-block;
					}
				}
			}

			.dashboard-button-group {
				margin: 0px 30px;
				position: relative;
				z-index: 1;
			}

			#notes {
				position: relative;
				float: left;
				width: 40%;
				padding: 15px 0;

				.notes {
					height: 100%;
					overflow: hidden;
					overflow-y: scroll;
					padding-bottom: 30px;
				}

				.trim {
					position: absolute;
					bottom: 15px;
					left: 0px;
					width: 100%;
					height: 45px;
					background-image: url("../img/up-trim-gradient-v.png");
					background-repeat: repeat-x;
					background-position: bottom left;
				}
			}
		}
		.DashboardPinnedComments {
			margin-top: 24px;
		}
	}

	@media (max-width: $address-break) {
		#address-card-wrap {
			overflow-y: auto;
			height: 175px !important;

			.addressCard {
				.addressText {
					.add-address {
						padding: 50px 10px;
					}
					.save-or-close {
						bottom: 0 !important;
					}
				}
			}
		}

		#map-wrapper {
			top: 321px !important;
		}

		.no-result {
			top: 321px !important;
		}
	}

	#address-card-wrap {
		position: fixed;
		top: 147px;
		left: 365px;
		right: 15px;
		z-index: 2;
		border-bottom: 2px solid rgba($black, 0.1);
		background-color: $grey-1;
		height: 140px;
		white-space: nowrap;

		.addressCard {
			position: relative;
			display: inline-block;
			width: 20%;
			min-width: 200px;
			vertical-align: top;
			height: 100%;

			&:last-child {
				width: calc(20% - 14px);
			}

			h2 {
				font-size: 18px;
				color: $blue;
			}

			.select2-wrap {
				position: relative;
				height: 30px;

				.select2-container-active {
					.select2-choice {
						color: $bright-blue;
						border-bottom: 1px solid $bright-blue !important;
						border-radius: 0;
					}
				}

				.select2-container {
					width: auto;
					position: absolute;
					right: 0;
					left: 0;
					font-size: 12px;

					.select2-choice {
						border: none;
						height: 30px;
						background-color: transparent;

						& > .select2-chosen {
							padding-left: 4px;
							padding-right: 4px;
						}

						abbr {
							right: 0;
						}

						.select2-arrow {
							display: none;
						}
					}
				}
			}

			.addressText {
				background-color: $grey-1;
				padding: 10px 5px;
				height: 100%;
				width: 100%;
				border-right: 1px solid $grey-4;

				.two-column {
					height: 27px;
					margin-bottom: 10px;
					.floating-label-input {
						width: calc(50% - 2px);
						&:first-child {
							float: left;
						}
						&:last-child {
							float: right;
						}
					}
				}

				.tools {
					display: none;
					font-size: 16px;

					.icon {
						padding: 3px;
						color: $grey-10;
					}

					.edit-icon {
						position: absolute;
						right: 25px;
						top: 8px;
						&.required {
							right: 7px;
						}
					}

					.delete-icon {
						position: absolute;
						right: 7px;
						top: 7px;
					}
				}

				.info-text {
					margin-top: 19px;
					margin-left: 5px;
					font-size: 12px;

					.flag-icon {
						margin-left: 3px;
					}

					.map-label {
						margin-top: -2px;
						margin-right: 5px;
						float: left;
					}
				}

				.add-address {
					padding: 35px 10px;
					text-align: center;
					height: 100%;
					background-color: $grey-4;
					color: $grey-10;
					border-radius: 3px;
					@include transition(all ease 200ms);

					.fa-plus {
						font-size: 22px;
					}

					.text {
						font-size: 12px;
						margin-top: 10px;
					}

					&:hover {
						background-color: $grey-6;
					}
				}

				.cantEdit {
					cursor: not-allowed;

					&:hover {
						background-color: $grey-4;
					}
				}

				.save-or-close {
					position: absolute;
					left: 0;
					right: 0;
					bottom: -34px;
					@include box-shadow(1px 1px 2px rgba($black, 0.2));

					.cancel-btn {
						width: 40%;
						border-radius: 0 0 0 2px;
						padding: 0px 6px;
					}

					.save-btn {
						width: 60%;
						float: right;
						border-radius: 0 0 3px 0;
						padding: 0px 6px;
					}
				}
			}

			&.active {
				background-color: $white;
				border-bottom: 2px solid $purple;

				.addressText {
					background-color: $white;
				}
			}

			&.empty {
				.addressText {
					border-right: 0;
				}
			}

			&:hover {
				.addressText {
					.tools {
						display: block;
					}
				}
			}
		}
	}

	#map-wrapper {
		position: fixed;
		top: 287px;
		bottom: 15px;
		right: 15px;
		left: 365px;

		.big-map-canvas {
			width: 100%;
			height: 100%;
		}
	}

	#account-apps {
		.color-style,
		.image-style {
			display: block;
			height: 24px;
			width: 24px;
			float: left;
			margin-top: 3px;
			margin-right: 8px;
			line-height: 30px;
			border-radius: 3px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		}

		.spacer {
			height: 100vh;
			padding: 20px;
		}

		.app-loader {
			margin: auto;
			margin-top: 50px;
		}
	}
}

#edit-account-form {
	#active-toggle {
		margin-top: 6px;
		font-size: 14px;

		.up-ios-toggle {
			margin-right: 10px;
		}

		._elevio_underline {
			margin-left: 10px;
		}
	}
}

// Needs to be at root-level
.manager-z-class {
	z-index: 1000;
}

.address-tab-component > .nav-tabs > li {
	a {
		line-height: 1;
	}

	&:hover {
		a:hover {
			background-color: $grey-4;
			border-color: $grey-4 $grey-4 $grey-7;
			height: 33px;
		}
	}

	&.active {
		a {
			border-color: $grey-8;
			border-bottom-color: transparent;
		}
	}
}

.customBackground {
	&--grey-1 {
		background-color: $grey-1 !important;
	}
	&--white {
		background-color: $white !important;
	}
}

.address-tab-component textarea {
	border: 1px solid $grey-8;
	box-shadow: none;
}

.address-tab-component .form-input-wrap {
	margin-top: 0;
}

.address-tab-component ul.nav.nav-tabs {
	border: none;
	position: relative;
	z-index: 50;
}

.address-tab-component .tab-content {
	position: relative;
	z-index: 5;
	top: -1px;
}

.address-tab-component .tab-content .forms-group {
	margin-bottom: 15px;
}

.address-tab-component .tab-content .row .col-md-3 .select2-container .select2-chosen {
	height: 34px;
	line-height: 21px;
}

.up-modal .up-modal-content .modal-in-modal {
	position: fixed;
	z-index: 5000;
	width: 580px;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 10em;
	background: #f6f9fb;
	height: 520px;
	box-shadow: 0 0px 130px rgba(0, 0, 0, 1);
	border-radius: 0 0 5px 5px;
	border-top: 4px solid $orange;

	.modal-close {
		position: absolute;
		right: 0;
		top: 0;

		i.fa {
			color: #6b7c93;
			padding: 17px 19px;
			font-size: 14px;
		}
	}

	h2 {
		color: #2f3947;
		font-size: 19px;
		font-weight: $regularFontWeight;
		line-height: 23px;
		width: 400px;
		margin: 80px auto 30px auto;
		display: block;
		text-align: center;
	}

	p {
		color: #4b5562;
		font-size: 14px;
		font-weight: $regularFontWeight;
		width: 460px;
		text-align: center;
		margin: 0 auto;
	}

	button {
		display: block;
		width: 170px;
		margin: 40px auto 30px auto;
	}

	.elevlink {
		display: block;
		text-align: center;
		font-size: 14px;
		margin: 0 auto;
		width: 154px;
		color: $bright-blue;
	}

	.table-example {
		width: 415px;
		background-color: #ffffff;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
		margin: 50px auto 30px;

		table {
			tr {
				border: 1px solid #f4f4f4;
				height: 40px;

				&:first-child {
					height: 30px;
				}

				td {
					font-size: 14px;
					padding-left: 20px;
					line-height: 17px;

					&.before {
						color: #6b7c93;
						font-style: italic;
					}

					i.fa.fa-barcode,
					i.icon.number {
						color: $bright-blue;
						font-size: 12px;
						margin-right: 8px;
						font-style: normal;
					}

					&.separator {
						font-size: 11px;
						color: #4b5562;
					}
				}

				th {
					padding-left: 20px;
					line-height: 17px;
					text-transform: uppercase;
					font-weight: $regularFontWeight;
					color: #4b5562;
				}
			}
		}
	}
}
