@import "~StyleVariables";

.KeyFiguresTable {
    width: 100%;
    border: 1px solid $grey-4;
    border-radius: $space * 2;
    background-color: white;

    &__header {
        background-color: $grey-1;
        padding: $padding 0;
    }

    &__row {
        background-color: $white;
        
        .TableColumn {
            padding: $padding;
        }
    }
}