@import "~StyleVariables";

.UserProspectingSettings {
	margin-bottom: 20px;
	padding: 0px 60px 5 * $space 5 * $space;

	&__checkbox-container {
		.Flex {
			cursor: pointer;
		}

		margin-bottom: 8px;
		.Checkbox {
			margin-right: 8px;
		}
	}

	&__country-list {
		margin-left: 32px;

		.Checkbox {
			margin-right: 8px;
		}
		&--hidden {
			max-height: 0;
		}
	}
	&__error {
		margin-left: 32px;
		margin-top: 4px;
	}
}

.TitledCheckbox {
	width: fit-content;
	cursor: pointer;

	&--isDisabled {
		cursor: not-allowed;
		&:hover {
			.Checkbox {
				opacity: 0.6;
			}
		}
	}
}
