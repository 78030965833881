@import "~StyleVariables";

.MailAISidebar {
	width: 320px;
    min-width: 320px;

	&.ListMail {
		padding: 0;
	}

	&__signalAnalysisContainer {
		width: 20px;
		height: 20px;
		border-radius: $border-radius;
		padding: $space;

		&--positive {
			color: $success-4;
			background-color: $success-1;
		}

		&--negative {
			color: $danger-4;
			background-color: $danger-1;
		}
	}
}
