@import "~StyleVariables";

.NavbarSubMenu {
	height: stretch;
	display: none;

	&--visible {
		display: flex;
	}

	&__item {
		opacity: 1;
		transform: translateX(0);
		visibility: visible;

		&--hidden {
			visibility: hidden;
			opacity: 0;
			transform: translateX(100%);
		}
	}

	&__show-more-trigger {
		position: absolute;
		right: 0;
		opacity: 0;
		visibility: hidden;
		transition: $transition-small-ease;
		transform: translateX(100%);
		height: stretch;
		color: rgba($white, 0.8);
		font-size: 13px;
		padding: 0 $space * 3;
		border-radius: $border-radius $border-radius 0 0;
		cursor: pointer;

		&--visible {
			transform: translateX(0);
			opacity: 1;
			visibility: visible;
		}

		&:hover {
			background-color: rgba($white, 0.1);
			color: $white;
		}
	}

	&__devider {
		background-color: $grey-1;
		border-top: 1px solid $grey-4;
		border-bottom: 1px solid $grey-4;
		padding: $space * 2.5 $space * 3;
	}
	&__setings {
		border-top: 1px solid $grey-4;
		padding: $space * 4 $space * 3;
	}
}
