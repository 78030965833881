.group-tree .additional-info,
#create-new-account-modal .additional-info,
#refresh-account .additional-info,
#create-new-account-modal .total-sum,
#refresh-account .total-sum {
	background: $grey-2;
	color: $grey-10;
	font-size: 10px;
	margin-left: 10px;
	border-radius: 2px;
}

.group-tree .additional-info,
#create-new-account-modal .additional-info,
#refresh-account .additional-info {
	padding: 3px 10px;
	position: relative;
	top: -1px;
}

#create-new-account-modal .total-sum,
#refresh-account .total-sum {
	padding: 5px 10px;
}

.create-account-modal {
	@media (min-width: ($modal-form-width + 110px)) {
		.up-modal-header {
			& > h5 {
				right: 370px !important;
				color: $blue;
			}
		}
	}

	@media (min-width: 1026px) {
		.tab-text {
			display: inline-block;
		}
	}

	@media (max-width: 1025px) {
		.tab-text {
			display: none;
		}
	}

	.up-modal-header {
		& > h5 {
			position: absolute;
			color: $blue;
			left: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			right: 270px;
		}
	}

	.up-modal-content {
		top: 30px;
		padding: 20px;
		background-color: $white;

		.search-field {
			padding-top: 10px;
			background-color: $white;
			padding-bottom: 12px;

			.search-icon {
				position: absolute;
				color: $grey-8;
			}

			label {
				top: 0;
				font-size: 16pt;
				color: $grey-8;
			}
			input {
				font-size: 16pt;
				color: $bright-blue;
				border: 0;
			}

			.has-value {
				label {
					font-size: 10pt !important;
					top: -18px !important;
				}

				input {
					border-bottom: 1px solid $bright-blue !important;
				}
			}

			#enter-manually {
				margin-top: 5px;
			}
		}

		.tables {
			position: relative;
			margin-top: 20px;

			table {
				table-layout: fixed;
			}

			.no-data {
				color: $grey-8;
				width: 210px;
				text-align: center;
				position: absolute;
				left: 10%;
				top: 220px;

				&.bisnode {
					left: 59%;
				}
			}

			.spinner {
				position: absolute;
				z-index: 99;
				margin-top: 250px;
				width: 100%;
				text-align: center;
				color: $grey-11;
				font-size: 30px;
			}

			.upsales-clients {
				width: 40%;
				float: left;
			}

			.other-clients {
				width: 59%;
				float: left;
				margin-left: 4px;
			}

			#account-searching-curtain {
				background-color: $grey-4;
				opacity: 0.5;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 1;
			}
			.load-text {
				position: absolute;
				z-index: 2;
				width: 100%;
				text-align: center;
				margin-top: 300px;
			}

			.table-title {
				margin-left: 20px;
				font-size: 16px;
			}

			.navbar {
				cursor: pointer;
				margin-bottom: -10px;
			}

			.table-overflow {
				min-height: 35vh;
				max-height: 57vh;
				overflow: hidden;
				overflow-y: auto;
				background-color: rgba($black, 0.02);

				&.left {
					background-color: transparent;
					max-height: 62vh;
				}

				.country-divider {
					color: $grey-10;
					background-color: $grey-1;
					padding: 5px;

					span {
						text-transform: uppercase;
						font-size: 12px;
					}
				}
			}

			.nav-tabs {
				border-bottom: 0;

				.disabled {
					a {
						color: $grey-4;
					}
				}

				li {
					background-color: $grey-1;
				}

				a {
					font-size: 12px;
					border-color: $grey-7;
					margin-right: -1px;
					border-radius: 0px;
					color: $grey-10;
					border-color: rgba($grey-10, 0.05);

					.icon {
						margin-right: 5px;
					}
				}

				a:hover {
					background-color: $white;
				}
				.active {
					a {
						color: $link-color;
					}
				}

				.to-many-hits {
					width: 0;
					height: 0;
					border-left: 12px solid transparent;
					border-top: 12px solid $orange;
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			.item {
				&.client-row {
					height: 50px;
					font-size: 12px;
					background-color: $white;
					border-bottom: 1px solid $grey-4;
					cursor: pointer;

					&.bisnode-left-col {
						width: 55%;
					}
				}

				.client-name {
					color: $bright-blue;
					margin-left: 20px;
					font-size: 14px;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-top: 4px;
					max-width: 100%;
					overflow: hidden;
					display: block;
				}

				.user-name {
					display: block;
					margin-left: 20px;
					color: $grey-10;

					.fa-user {
						margin-right: 8px;
					}
				}

				.sni-text {
					display: inline-block;
					float: right;
					margin-right: 20px;
					max-width: 240px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-transform: uppercase;
				}

				.employee-turnover {
					float: right;
					padding-right: 20px;
					width: 100%;

					.employer {
						float: right;
						width: 105px;

						.employee-icon {
							float: right;
							margin-left: 10px;
							margin-top: 1px;
							margin-right: 3px;
						}
					}

					.currency {
						font-size: 9px;
						text-transform: uppercase;
						margin-right: 10px;
						margin-left: 2px;
						float: right;
					}
				}

				.hover-info {
					position: absolute;
					right: 0;
					left: 0;
					text-align: center;
					margin-top: -20px;

					.hover-text {
						margin-left: 2px;
						line-height: 0px;
						width: 80px;
						color: $bright-blue;
						visibility: hidden;
					}
				}

				&:hover {
					background-color: rgba($super-light-blue, 0.96);

					&.update {
						background-color: $grey-1;
						.hover-text {
							color: $grey-10;
						}
					}

					.hover-text {
						visibility: visible;
					}
				}
			}
		}

		#find-prospect-info {
			position: absolute;
			padding: 15px;
			height: 70px;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $grey-1;

			.content {
				position: relative;

				.subtitle {
					color: $grey-10;
					font-size: 10pt;
				}

				.right-link {
					position: absolute;
					padding: 10px;
					padding-right: 0px;
					cursor: pointer;
					right: 0px;
					top: 0px;
					color: $bright-blue;
					transition: all ease 200ms;

					.fa-search {
						margin-right: 9px;
					}

					.fa-arrow {
						margin-left: 20px;
						font-size: 16pt;
						color: $grey-10;
					}

					.fa-chevron-right {
						margin-left: 10px;
						color: $grey-10;
					}
				}
			}

			&:hover {
				background-color: $grey-2;

				.right-link {
					padding-right: 10px;
				}
			}
		}
	}
}

#create-new-account-modal {
	height: 100%;

	.huge-input-container {
		i {
			left: 14px;
			top: 10px;
		}

		input {
			padding-left: 50px;
			border-bottom: none;
			margin: 3px 0;
		}
	}

	.header-divider {
		height: 1px;
		background-color: $grey-6;
		display: block;
	}

	.tab-wrap {
		height: 50px;

		button.pull-right {
			margin-top: 8px;
			padding-right: 15px;
		}
	}

	.Tab {
		box-sizing: content-box;
	}

	.Tab--selected {
		.total-sum {
			margin-left: 8px;
		}
	}

	.account-tabs {
		width: 100%;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

		.Tab--disabled {
			color: $grey-5;

			.flag-icon-wrap {
				filter: grayscale(100%);
				opacity: 0.5;
			}
		}
	}

	.flag-icon-wrap {
		display: inline-block;
		vertical-align: text-top;

		border: 1px solid rgba($blue, 0.8);
		height: 16px;
		width: 20px;
		border-radius: 3px;
		margin-right: 5px;

		.flag-icon {
			vertical-align: top;
			border-radius: 2px;
		}
	}

	.Tab--selected {
		.flag-icon-wrap {
			box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
		}
	}

	.btn-link:hover {
		text-decoration: none;
	}

	button.back-to-search {
		position: fixed;
		top: 14px;
		right: 60px;
		height: 34px;
		color: $grey-10;
		@include transition(background 0.3s);
		border: none;
		background: rgba($grey-3, 0.6);

		&:hover {
			background: rgba($grey-3, 1);
		}
	}

	.breadcrumb-link {
		font-size: 16px;
		line-height: 1.1;
		padding-left: 0;
		padding-right: 0;
		height: auto;
	}

	.indicator-wrapper > div {
		background-color: rgba($black, 0.05);
		color: rgba($black, 0.3);

		&:hover {
			background-color: rgba($black, 0.09);
		}

		&.indicator-active {
			background-color: $bright-blue;
			color: $white;

			&:hover {
				background-color: darken($bright-blue, 10%);
			}
		}
	}

	.header-content {
		.full-screen-small-header {
			color: $green;
			margin-left: 15px;
			margin-bottom: 0px;
		}

		.FindProspectsBranchSubtitle {
			padding-left: 20px;
		}
	}

	.no-height {
		height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		border-collapse: collapse;

		* {
			height: 0 !important;
			padding: 0 !important;
			margin: 0 !important;
			border-collapse: collapse;
		}
	}

	.box-shadow {
		box-shadow: 0 3px 3px rgba($black, 0.2);
		background-color: white;
		position: relative;
		z-index: 1000000;
		overflow: hidden;
	}

	.upsales-loader {
		margin: 10px auto 0 auto;
	}

	.search-results {
		background: $grey-2;
		opacity: 1;
		position: fixed;
		top: 177px;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 20px 20px 32px;

		@include transition(opacity 0.3s);
		overflow: scroll;

		&.no-opacity {
			opacity: 0;
		}
	}

	.add-as-new-btn {
		margin-left: 5px;
	}

	.search-tabs {
		img {
			max-height: 26px;
			margin-left: 15px;
			box-shadow: 0 1px 2px rgba($black, 0.2);
			margin-right: 10px;
			border-radius: 2px;
		}

		.Tab:first-child {
			img {
				margin-left: 0;
			}
		}
	}

	ul.tab-list {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			padding: 10px 0;
			min-width: 190px;
			text-align: left;
			border-bottom: 3px solid transparent;
			line-height: 26px;
			transition: all ease 200ms;

			&.link {
				cursor: pointer;
				color: $grey-10;

				&:hover,
				&.active {
					&:not(.disabled) {
						color: $blue;

						.total-sum {
							font-weight: $regularFontWeight;
						}
					}
				}

				&:hover {
					border-bottom: 3px solid $grey-8;
				}

				&.active {
					border-bottom: 3px solid $blue;
					font-weight: $boldFontWeight;
				}

				&.disabled {
					cursor: not-allowed;
					color: $grey-5;

					.tab-icon {
						filter: grayscale(100%);
						opacity: 0.5;
					}
				}
			}

			.tab-text {
				margin: 0 10px;
				margin-right: 4px;
			}

			.tab-icon {
				max-height: 26px;
				margin-left: 15px;

				img {
					height: 26px;
					width: auto;
					border-radius: 2px;
				}
			}

			.total-sum {
				margin-right: 15px;
				padding: 0 10px;
				margin-left: 0;
				background: none;
			}
		}

		&.border-bottom {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: -3px;
				height: 3px;
				background: linear-gradient(to bottom, rgba($black, 0.1) 0%, rgba($black, 0) 100%);
			}
		}
	}

	.header-content .tab-list {
		padding: 0 20px;

		.link {
			padding-bottom: 8px;
		}
	}

	.show-group {
		.header-content {
			height: 113px;
		}

		.full-screen-small-header {
			margin-left: 20px;
			margin-bottom: 10px;
		}
	}

	.merge {
		max-width: 800px;
		margin: auto;
		margin-top: 10%;

		.title {
			font-size: 26px;
			font-weight: $boldFontWeight;
			text-align: center;
			margin-bottom: 10px;
		}

		.subtitle {
			color: $grey-10;
			font-size: 16px;
			text-align: center;
			margin-bottom: 15px;

			& + .subtitle {
				margin-bottom: 40px;
				font-size: 14px;
				font-style: italic;
			}
		}

		.subtitle-name {
			font-weight: $boldFontWeight;
			color: #333;
		}

		.account-select-wrapper {
			display: flex;
			justify-content: center;

			.account-select {
				width: 100%;
				z-index: 1000;
			}

			.select2-dummy-wrap {
				width: 300px;
				position: relative;
			}

			.select2-dummy {
				top: 0;
				left: 0;
				border: 1px solid;
				border-color: #a4b3c7;
				border-radius: 3px;
				height: 34px;
				width: 100%;
				background-color: white;
				position: absolute;
				z-index: 0;
				width: 100%;
			}

			.apply-to {
				line-height: 34px;
				vertical-align: middle;
				display: inline-block;
				background: $grey-2;
				color: $grey-10;
				padding: 0 10px;
				border-radius: 2px;
			}

			.Icon {
				margin-right: 5px;
			}
		}

		.abort-wrap {
			margin-top: 10px;
			display: flex;
			justify-content: center;
		}
	}

	.required-fields {
		padding: 200px 20px 0 20px;
		margin: 0 auto;
		max-width: 600px;

		.half {
			width: 50%;
			padding: 0 10px;
			vertical-align: top;
			display: inline-block;

			&.no-padding-left {
				padding-left: 0;
			}

			&.no-padding-right {
				padding-right: 0;
			}
		}

		h1 {
			font-size: 26px;
			font-weight: $boldFontWeight;
			color: $grey-11;
		}

		p {
			font-size: 16px;
			color: $grey-10;
		}

		.add-account-btn {
			margin-top: 40px;
		}
	}

	.create-account-content__icon {
		margin-right: 2px;
	}

	.create-account-content__text {
		font-size: 14px;
		line-height: 14px;
		display: block;
	}

	.create-account-content__subtext-wrap {
		line-height: initial;
		margin-top: 3px;
	}

	.create-account-content__subtext {
		display: inline-block;
		font-size: 11px;
		line-height: 11px;
		color: $grey-10;

		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.accounts-card {
	background: $white;
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	border-radius: 2px;

	.header {
		padding: 10px 15px;
		line-height: 23px;

		.total-sum {
			vertical-align: middle;
		}

		h2 {
			line-height: 23px;
		}

		img {
			box-shadow: 0 1px 2px rgba($black, 0.2);
			margin-right: 10px;
			border-radius: 2px;
			vertical-align: top;
		}

		.TableColumn {
			padding: 0;

			& > button {
				width: 100%;
				text-align: left;
				text-decoration: none;
			}
		}

		.ButtonSelect {
			float: right;
		}

		.DropDownMenu {
			float: right;

			& > button {
				text-decoration: none;

				& > .Icon {
					vertical-align: top;
					margin: 8px 0 0 5px;
				}

				& > .total-sum {
					margin: 0 8px 0 0;
				}
			}
		}
	}

	.no-highlight .Tabs__selected-slider {
		display: none;
	}

	.up-paginator-wrap {
		text-align: center;
		display: block;
		margin-bottom: 10px;
		padding-top: 10px;
		z-index: 1000;
		position: relative;
		border-top: 1px solid $grey-6;
	}

	.pagination > li {
		display: inline;
	}

	.result-table {
		table-layout: fixed;

		.loading-row {
			td {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.upsales-loader {
			margin: auto;
			height: 50px;
			width: 50px;
		}

		.account-row.inactive {
			td {
				background-color: $grey-2;
			}

			td:first-child {
				border-left: 4px solid $grey-8;
			}
		}

		&.group-tree {
			.account-row-lg-height,
			.account-row {
				border-left: 3px solid rgba($black, 0);
				border-right: 3px solid rgba($black, 0);

				&.existing-account {
					border-left: 3px solid $grey-8;
				}

				&.selected-account-green-border {
					background: $super-light-blue;
					border-left: 4px solid $green;
					border-right: 4px solid $green;

					.row-text {
						font-weight: $boldFontWeight;
					}
				}

				&.selected-account {
					background: $super-light-blue;
					border-left: 3px solid $bright-blue;
					border-right: 3px solid $bright-blue;

					.row-text {
						font-weight: $boldFontWeight;
					}
				}

				td {
					overflow: visible;
				}

				td.journey {
					overflow: hidden;
				}
			}
		}

		th {
			padding: 5px 15px;
			font-weight: $regularFontWeight;
			font-size: 12px;
			border-top: none;
		}

		.TableHeader__column {
			// /**
			// * @todo  @upsales/components 2.19.13
			// * STYLING: TableHeader has a transition that makes the table header jump when the table is loaded.
			// * @link https://github.com/upsales/ui-components/issues/566
			// */
			transition: none;
			&:first-of-type {
				z-index: 1;
			}
		}

		.buy-button {
			.expand-on-button-hover {
				max-width: 0;
				text-overflow: initial;
				transition: max-width ease-in 200ms;
				display: inline-block;
			}

			&:hover {
				.expand-on-button-hover {
					max-width: 300px;
				}
			}
		}

		tbody > tr {
			line-height: 40px;
			border-top: 1px solid $grey-6;

			&:hover {
				background: $grey-1;

				.show-on-row-hover {
					display: initial;
				}

				.expand-on-row-hover {
					max-width: 300px;
				}

				.margin-left-on-hover {
					margin-left: 5px;
				}

				.hide-on-row-hover {
					display: none;
				}
			}

			.show-on-row-hover {
				display: none;
			}

			.margin-left-on-hover {
				margin-left: 0;
			}

			.expand-on-row-hover {
				max-width: 0;
				text-overflow: initial;
				transition: max-width ease-in 200ms;
				display: inline-block;
			}

			&.has-account-id:hover {
				cursor: pointer;
			}

			&.existing-account {
				background: $grey-1;
			}

			&.subaccount {
				background: $grey-1;
			}

			&.invalid-settings-row {
				height: 51px;
				text-align: center;
			}

			&.load-more-rows-row {
				line-height: 20px;
				text-align: center;

				td {
					padding: 0;
				}
			}

			td {
				padding: 5px 15px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				span {
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.row-text {
					font-size: 14px;
					line-height: 14px;
					display: block;
				}

				.no-account-manager {
					color: $grey-10;
					font-style: italic;
				}

				.subtitle {
					font-size: 11px;
					line-height: 11px;
					margin-top: 5px;
					display: block;
					color: $grey-10;
				}

				.grey-text {
					color: $grey-10;
				}
			}
		}
	}
}

.accounts-card-prospecting .account-row {
	height: 50px;
}

.account-row-lg-height {
	height: 64px;
}
