@import "~StyleVariables";

.EditProjectPlanType {
	position: relative;

	.ModalHeader {
		height: 50px;
		padding: 0;
	}

	.ModalHeader__content {
		padding: 0;
	}

	.ModalContent {
		background-color: $white;
		height: calc(100% - 50px);
	}

	&__header-content {
		width: 100%;

		.Tab {
			display: flex;
			align-items: center;
		}
	}

	&__content {
		padding-top: $space * 10;
		max-width: 900px;
		margin: auto;

		.Input {
			width: 600px;
		}

		.Select {
			width: 600px;
		}
	}

	&__color {
		border: 1px solid $grey-8;
		border-radius: $border-radius * 2;
		text-transform: uppercase;
		text-align: center;
		height: 24px;
		width: 72px;
	}

	&__stages-card {
		width: 100%;

		.CardHeader__title {
			font-size: 16px;
		}

		.CardHeader__subtitle {
			font-size: 12px;
		}
	}
	
	&__title-wrapper {
		max-width: 300px;
	}
}
