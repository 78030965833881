.ProspectingCountryChip,
.ProspectingCountryChipDropdown {
	max-width: 170px;

	&__Country {
		p {
			margin-bottom: 0px;
			max-width: 170px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.Flag {
			min-width: 20px;
		}
	}
}
