@import "~StyleVariables";

.ChangesOnAgreement {
	position: relative;
	margin: 12px;

	&__card {
		height: 75px;
		border-radius: $border-radius;
		transition: border-radius 0.2s ease-in-out;

		&--clickable {
			cursor: pointer;
		}

		&--open {
			border-radius: $border-radius $border-radius 0 0;
		}
	}

	&__header {
		height: 55px;

		background-color: $success-1;

		&--negative {
			background-color: $danger-1;
		}

		&--hidden {
			background-color: $grey-2;
		}

		&__previous {
			text-decoration: line-through;
			margin-right: 4px;
		}
	}

	&__rows {
		background-color: $white;

		&--notLast {
			padding-bottom: 12px;
		}
	}
}
