@import "~StyleVariables";

.ColorChip {
	border: 1px solid $grey-8;
	border-radius: $border-radius * 2;
	text-transform: uppercase;
	text-align: center;
	height: 24px;
	width: 72px;
}
