@import "~StyleVariables";

.RequestedTier {
	background-color: $white;
	.Card {
		box-shadow: none; // :D
	}

	.Animate--visible {
		margin-top: $space * 2;
	}
}
