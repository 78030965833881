@import "~StyleVariables";

.TableColumn {
	.DropDownMenu > .Button:hover {
		background-color: $grey-2;
		color: $grey-10;
	}

	.DropDownMenu__content {
		width: max-content;
		animation: fadeIn 0.3s;
		min-width: initial;
	}
}
