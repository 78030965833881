@import "~StyleVariables";

.EditTicket {
	.Drawer__children {
		height: 100%;
	}

	.DrawerHeader__text-col {
		max-width: fit-content;
	}

	&__header {
		z-index: 20;
	}

	&__loader {
		height: 100%;
		display: flex;
		width: 1120px;
		padding-top: $space * 35;
		justify-content: center;
	}

	.TextEditor {
		border: $border;
		border-radius: $border-radius;
		overflow: hidden;
	}

	.MailAttachment {
		width: fit-content;
	}

	&__content {
		display: flex;
		height: calc(100% - 50px);
		background-color: $white;
		overflow-y: auto;

		&__ticketContent {
			height: fit-content;
			min-height: 100%;
			width: 700px;

			border-right: $border;
			margin-right: -1px;
			padding: $space * 4;

			display: flex;
			flex-direction: column;

			> .Title {
				margin-bottom: $space * 2;
				word-break: break-word;
			}

			&__subTitle {
				display: flex;
				gap: $space;
			}

			&__name,
			&__client {
				max-width: 150px;
			}

			&__actions {
				display: flex;
				flex-wrap: wrap;
				gap: $space * 3;

				margin-top: $space * 2;
				margin-bottom: $space * 8;
			}
		}
	}

	&__comment {
		border-bottom: 1px solid $grey-4;

		&__header {
			& > .Text {
				display: inline-block;
				margin-right: $space;
			}
			.EllipsisTooltip {
				max-width: 325px;
			}
			.inline-confirm-container {
				right: 20px;
				top: 67px;
			}
		}

		.MentionsInput__mention {
			background-color: $grey-4;
		}

		.DraftEditor-root {
			padding-left: 0;
		}

		.MailAttachment {
			width: fit-content;
		}

		iframe {
			all: unset;
			width: 619px;
			overflow-y: hidden;
		}
	}

	&__mention-input {
		border: 1px solid $grey-6; // TODO: replace with $border variable that is added in another PR
		border-radius: $border-radius;

		.DraftEditor-root {
			min-height: 60px;
		}
	}

	&__saved-banner {
		position: absolute;
		right: $space * 15;
		top: 50%;
		margin-top: -10px;
	}

	&__header-actions {
		position: absolute;
		right: $space * 2;
		top: 20%;
	}

	form {
		height: 100%;
	}

	&__expandedIcon {
		&--expanded {
			transform: rotate(180deg);
			transition: transform 200ms linear;
		}
		transform: rotate(0deg);
		transition: transform 200ms linear;
	}

	&__status {
		.ClickableItem__icon-col {
			margin-left: $space;
		}

		&--selected {
			border-left: $space solid $green;

			.ClickableItem__icon-col {
				margin-left: initial;
			}
		}
	}

	&__dropdown-actions {
		z-index: 1000000;
		.inline-confirm-modal,
		.inline-confirm-modal-hidden {
			width: 100%;
		}
	}

	.CustomFields {
		margin-bottom: unset;
	}
	&__titleInput {
		width: 100%;
	}

	&__external-contact-option {
		.ClickableItem {
			margin: 0;
		}

		& > .Block {
			.Text {
				max-width: 300px;
				line-height: 125% !important; // ButtonSelect has "line-height: 1 !important" which causes the text to be cut off in this case, 125% is the default for text-md
			}
		}
	}

	&__external-contact-dropdown {
		.tippy-popper {
			word-wrap: break-word !important;
		}
	}

	&__external-contact-dropdown-header {
		background: $grey-1;
		padding: $space * 3;
	}

	&__external-contact-dropdown-item {
		height: $space * 14;
		padding: 10px $space * 5;
		border-top: 1px solid $grey-4;
		white-space: nowrap;
		text-align: left;
		&--active {
			box-shadow: inset 4px 0 $green;
		}

		&:hover {
			background: $grey-1;
		}
	}

	&__external-contact-dropdown-item-subtitle {
		height: 13px; // Prevents letters from being cut off
		max-width: 400px;
	}

	&__external-contact-dropdown-item-title {
		height: 15px;
		max-width: 320px;
	}

	&__reply-mode-select {
		--button-select-width: 0px;
	}

	&__reply-mode-select--withDropdown {
		.Button:last-of-type {
			padding-right: $space;
		}

		.DropDownMenu__content {
			max-height: 400px;
			overflow: auto;
			width: 450px;
			left: calc(
				var(--button-select-width) * -1 + 36px
			) !important; // Epic hack to make the dropdown align with the left side of the buttonselect
			top: 32px;
		}
	}

	&__reply-mode-select--dropdownOpen {
		.Button:last-of-type {
			&:hover {
				filter: none;
				box-shadow: none;
			}
		}
	}

	&__reply-mode-select-wrapper {
		z-index: 10;
	}

	&__chat-tabs-wrapper {
		z-index: 10;
		.Tabs {
			--tabs-width: 0px;
			overflow: visible;
			.DropDownMenu__content {
				max-height: 400px;
				overflow: auto;
				width: 450px;
				left: calc(
					var(--tabs-width) * -1 + 48px
				) !important; // Epic hack to make the dropdown align with the left side of the buttonselect
			}
		}
	}

	&__send-button {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 400px;
	}

	.tippy-popper {
		word-wrap: break-word !important;
	}

	.SplitButtonWithActions {
		.DropdownContainer {
			z-index: 20;
		}
	}

	&__attachment-error {
		margin-top: $space;
		height: unset;
		max-width: 600px;
		width: fit-content;
		padding: $space;

		.Chip > .Text {
			white-space: unset;
			text-overflow: unset;
		}
	}
}
