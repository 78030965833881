@import "~StyleVariables";
@import "~StyleAnimations";

.EditProjectPlanTemplate {
	grid-template-rows: auto 0 1fr 0;

	&--width {
		--modalContentWidth: 1000px;
	}

	&--categoryWidth {
		--modalContentWidth: 620px;
	}

	.Label {
		font-weight: bold;
	}

	.ModalHeader {
		padding: 0;
	}

	.ModalHeader__content {
		padding: 0;
	}

	.Tabs {
		white-space: nowrap;
	}

	.Title {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__ProjectSettings {
		height: 100%;
		position: relative;

		// Needs to be fixed in the ui-library
		.SelectMultiInput__clear {
			display: none;
		}

		.CustomFields {
			.FormComponent {
				display: block;
			}

			.Input {
				width: 100%;
			}
		}
	}

	&__controls {
		white-space: nowrap;
		margin-left: auto;
	}
}

.EditProjectPlanTemplate,
.EditTaskTemplate {
	&__ModalTagListButton {
		position: absolute;
		width: max-content;
		top: $header-height + 6 * $space;
		right: 6 * $space;
		z-index: 1;
		width: max-content;
	}

	$tag-list-width: 250px;

	#tag-list {
		top: $header-height;
		width: $tag-list-width;
		right: -$tag-list-width;
		@include transition(right 200ms ease);

		#hide-tags-btn {
			position: absolute;
			top: 5px;
			right: 10px;
		}
	}

	.ModalContent {
		margin-right: 0 !important;
		@include transition(margin-right 200ms ease);
	}

	&--tagListVisible {
		#tag-list {
			right: 0;
		}

		.ModalContent {
			margin-right: $tag-list-width !important;
		}
	}

	.TemplateFiles {
		border: $border;
		border-radius: $border-radius;
		margin-top: 50px;
		background-color: $grey-1;
		overflow: hidden;

		.Block {
			background-color: $white;
		}
	}
}
