@import "~StyleVariables";

.InlineConfirm {
	&--customerSupport {
		.inline-confirm-container {
			text-align: left;

			.inline-confirm-control {
				display: flex;

				.Button:first-child {
					width: 45%;
				}

				.Button:last-child {
					width: 45%;
				}
			}
		}
	}

	&--ticketMerge {
		.inline-confirm-control {
			display: flex;
			flex-direction: column;

			.Button {
				width: 100%;
			}

			.Button:first-child {
				margin-top: $space * 3;
			}
		}
	}
}
