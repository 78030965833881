@import "~StyleVariables";

.CustomerPortfolioPotentialDrawer {
    width: 800px;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;

    &__contentWrapper {
        overflow-y: scroll;
        flex: 1;
        padding-bottom: 25vh;
    }

    &__spacedContent {
        padding: $space * 5 $space * 10;
    }

    &__clientTable {
        border: $border;
        border-radius: $border-radius;
    }

    .DrawerHeader {
        position: sticky;
        top: 0;
    }

    .Drawer__children {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}