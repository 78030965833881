@import "~StyleVariables";

.ProjectTasks {
	margin-bottom: $space * 20;

	.TableRow {
		cursor: pointer;
	}

	.DeleteConfirm {
		z-index: 2;
	}

	.DropDownMenu {
		&__content {
			z-index: 2;
		}
	}

	.SplitButtonWithActions {
		.DropdownContainer {
			z-index: 2;
		}
	}

	&__column {
		flex-grow: 1;
	}

	&__taskTitle {
		width: fit-content;
		max-width: 400px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		&--strike {
			position: relative;
			color: $grey-10;
			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: $black;
			}
		}
	}
	&__icon {
		animation: rotateBackAnimation;
		animation-iteration-count: 1;
		animation-duration: 400ms;
		transform: rotate(0deg);

		&--rotate {
			animation: rotateAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(180deg);
		}
	}

	&__completed {
		&--grey {
			background-color: $grey-1;
		}
	}

	&__template-select-wrapper {
		.Select {
			width: 300px;
		}

		.Text {
			max-width: 280px;
		}
	}
}
