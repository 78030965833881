@import "~StyleVariables";
@import "../../styles/mixins";

.Login {
	$input-height: 50px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	background-color: #0f1c22;

	&:before,
	&:after {
		content: "";
		position: fixed;
	}

	&:before {
		background-image: url("./LoginBg.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 1938px;
		height: 1792px;
		bottom: -550px;
		right: -370px;
		z-index: 1;
	}

	&:after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: radial-gradient(116% 185% at 108% 116%, #086471 0%, transparent 65%, transparent 100%);
		z-index: 1; // Place between background image and content
	}

	// hack for making it stay on top, remove when routes are fixed
	position: fixed;
	overflow: auto;
	z-index: 9999;

	.Animate {
		height: 0;
		overflow: hidden;
		opacity: 0;
	}

	.Animate.Animate--visible {
		height: auto;
		overflow: auto;
		opacity: 1;
	}

	&__logo {
		position: absolute;
		top: $space * 10;
		left: $space * 10;
		z-index: 3;

		.Logo {
			display: inline-block;
			svg {
				width: 100px;
			}
		}
	}

	&__left {
		flex: 0 0 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: rgba(#0f1a21, 0.9);
		position: relative;
		z-index: 2;
	}

	&__right {
		flex: 1;
		padding-top: 25vh;
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: center;
	}

	&__env-msg {
		display: inline-block;
		text-shadow: 1px 0 4px rgba(30, 37, 46, 0.4);
		text-transform: uppercase;
		letter-spacing: -1px;
		position: relative;
		bottom: 6px;
		left: $space;
		font-weight: 600;
	}

	&__main {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 20px;
		z-index: 2;
	}

	&__main-inner {
		width: 100%;
		max-width: 400px;
		margin: auto 0;
		position: absolute;
		top: 25vh;
	}

	&__form {
		width: 100%;
		position: absolute;

		.Input {
			height: $input-height;
		}

		.Input:not(.Input--error) .Input__input {
			border-color: $greyeen;
		}

		.Input__input {
			height: $input-height;
			line-height: $input-height;
			background-color: $greyeen;
			color: $white;
			@include placeholder-color(rgba($super-light-green, 0.7));

			&:focus,
			&:active {
				color: $green;
				@include placeholder-color($green);
				background-color: $white;
				outline: none;
			}
		}
	}

	&__links {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		height: 50px;
		padding: 0 20px;
		z-index: 1;
	}

	@media screen and (max-width: 1200px) {
		&__left {
			flex: 0 0 60%;
		}
	}

	@media screen and (max-width: 1024px) {
		background-color: $dark-green;

		&__right {
			flex: 0;
		}

		.LoginTip {
			display: none;
		}

		&__left {
			flex: 1;
		}

		&__links {
			justify-content: center;
		}

		&__main {
			margin-top: 90px;
		}
	}
}
