@import "~StyleVariables";

.FocusMode {
	position: fixed;
	z-index: 10000;
	height: 100vh;
	width: calc(100vw - 1000px);
	background-color: $green;
	left: 0;
	top: 0;
	transition: transform 300ms, opacity 300ms;
	overflow-y: auto;
	opacity: 0;
	transform-origin: left;
	transform: scaleX(0);

	&--hasGroupBonanza {
		width: calc(100vw - 1100px);
	}

	&--open {
		transform: scaleX(1);
		opacity: 1;
	}

	&__header {
		display: flex;
		align-items: center;
		top: 0;
		width: 100%;
		height: 52px;
	}

	&__exit-button {
		margin-right: 10px;
	}

	&__header-remaining-calls {
		margin-left: 10px;
	}

	&__stat-row {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		&--non-activities {
			justify-content: space-evenly;
		}
	}

	&__total-outcomes {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__call-stats {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	&__content {
		margin-top: 30px;
		display: flex;
		align-items: center;
		max-width: 800px;
		flex-wrap: nowrap;
		margin: 0 auto;
	}

	@media (max-width: 1000px) {
		& {
			width: calc(100% - 750px);
		}
	}
	&__topLists {
		margin-top: 30px;
		width: 100%;
	}
}
