@import "~StyleVariables";

.HideTabs__tableHeader {
	.TableHeader__column:first-child {
		width: 50%;
	}
	.TableHeader__column {
		width: 25%;
	}
	.TableHeader__column:last-child {
		text-align: right;
		padding-right: 16px;
	}
}

.HideTabs {
	&__DisabledRow {
		box-shadow: inset 4px 0 $grey-8;
		background: $grey-1;
	}
	&__section {
		background: $grey-1;
	}
}
