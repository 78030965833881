@import "~StyleVariables";

.ProjectProductList {
	&__main-block,
	&__table-wrapper {
		border: $border;
		border-radius: $border-radius;
		overflow: hidden;
	}

	&__productList-wrapper {
		border: $border;
		border-radius: $border-radius;
		padding: $space * 3 $space * 4;
	}

	&__table-wrapper--inlineVisible {
		overflow: visible;
	}

	&__list-title {
		max-width: 300px;
	}

	&__row-actions {
		height: 100%;
	}

	&__sum-rows {
		width: 200px;
	}

	&__button-row {
		margin-top: 30px;
	}

	&__clickable:hover {
		cursor: pointer;
	}

	&__product-column {
		width: 300px;
	}

	&__product-select {
		width: 100%;
	}

	&__quantity-column {
		width: 100px;
	}

	&__order-table-name {
		width: 250px;

		.Text {
			width: 250px;
		}
	}

	.TableColumn {
		padding-top: $space * 2;
		padding-bottom: $space * 2;
	}

	&__empty-table {
		background-color: $grey-2;
		height: 50px;
	}

	.Icon:hover {
		cursor: pointer;
	}

	&__productList-input {
		width: 50%;
	}

	&__edit-productList-input {
		flex: 1;
	}

	.Progress {
		top: 0px;
	}

	&__icon-button {
		.Icon {
			margin: 0;
		}
	}
}
