@import "~StyleVariables";

.Map {
	height: 100%;
	width: 100%;

	--markerDetailsWidth: 376px;
	--searchBoxWidth: 300px;

	&__map,
	&__loader {
		height: 100%;
		width: 100%;
	}

	&__marker {
		position: relative;

		&.highlight .Map__markerDetails {
			display: flex;
		}
	}

	.gmnoprint {
		font-family: Inter, sans-serif;
	}


	&__markerIconWrapper {
		width: 26px;
		height: 26px;
	}

	&__markerIcon {
		position: absolute;
		top: 8px;
	}

	&__markerPin {
		transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
		border-radius: 50% 50% 50% 0;
		padding: 0 3px 3px 0;
		width: 26px;
		height: 26px;
		background: var(--backgroundColor);
		@include boxShadow(lg, false, false);
		border: 2px solid var(--borderColor);


	}

	&__markerDetails {
		position: absolute;
		top: 35px;
		display: none;
		flex-direction: column;
		flex: 1;
		width: var(--markerDetailsWidth);
		padding: $space * 3;
	}

	&__divider {
		background-color: $grey-4;
		height: 1px;
		width: 100%;
	}

	&__pinnedContent {
		width: 300px;
	}

	&__closeButton {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
		width: 24px;
		height: 24px;
		justify-content: center;
		align-items: center;
	}

    &__searchBox {
		left: 0 !important; // Google maps tries to move searchbox
        margin: 8px;
        width: var(--searchBoxWidth);
    }

	&__quickFilters {
		position: absolute;
		top: 0;
		left: calc(var(--searchBoxWidth) + 16px) !important; // Google maps tries to move quickfilters
		margin: 8px;

		.ReportcenterFilter {
			border-radius: 4px;
		}

		.ReportcenterFilter__trigger {
			background-color: $white;
		}

		.ReportcenterFilterDateSelect {
			width: auto;

			.Text {
				white-space: nowrap;
			}
		}
	}

	.gm-style-iw,
	&__markerDetails {
		border-radius: 4px;
		box-shadow: $box-shadow-md;
	}
}
