@import "~StyleVariables";

.ProspectingCardLarge {
	cursor: pointer;

	&:not(.ProspectingCardLarge--disabled):hover {
		box-shadow: 0 3px 6px 0 rgba($black, 0.2);
	}
	
	&--disabled {
		cursor: default;
	}

	.Text {
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.Title {
		white-space: nowrap;
	}

	.Flex {
		flex-wrap: wrap;
		padding-left: $space * 3;
	}
}

.ProspectingCardLarge__header {
	display: flex;
	align-items: center;

	img {
		height: 28px;
		width: 28px;
		border-radius: 4px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		margin-right: 15px;
	}

	.BadgeLogo {
		margin-right: 16px;
	}

	.Headline--sm {
		font-size: 18px;
		line-height: 26px;
	}
}
